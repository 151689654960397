import React, { useEffect } from 'react';
import './css/style.css';
import Index from './jsx';
import { analytics } from './firebase'; // Import Firebase Analytics

function App() {
  useEffect(() => {
    // Log an event using Firebase Analytics
    //analytics.logEvent('page_view', { page_path: window.location.pathname });

    // You can track other events or set user properties as needed
    // analytics.logEvent('button_click', { button_name: 'example_button' });
    // analytics.setUserId('user123');
  }, []);

  return (
    <div className="App">
      <Index />
    </div>
  );
}

export default App;
