import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/UserAuthContext";

function Otp1() {


    const {  getClient, user, transactions  } = useUserAuth();
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState(null); // State to hold the user data
    const [selectedLanguage, setSelectedLanguage] = useState('en'); // Default language is English
    // const [phoneNumber, setPhoneNumber] = useState();

    useEffect(() => {
        // Fetch the user data when the component mounts
        async function fetchUserData() {
            try {
                const userData  = await getClient(user.uid);
                setCurrentUser(userData);
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        }

        if (user) {
            fetchUserData();
           
           
        }
    }, [getClient, user]);





// const handlePhoneNumberChange = (e) => {
//     setPhoneNumber(e.target.value);
//     };


    return (
        <>
            <div className="authenication section-padding">
                <div className="container h-100">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-xl-5 col-md-6">
                            <div className="mini-logo text-center my-5">
                                <Link to={"/"}>
                                    <img
                                        src={require("../../images/reoncapital_logo_transparent_small_small_v2.png")}
                                        alt=""
                                    />
                                </Link>
                            </div>
                            <div className="auth-form card">
                                <div className="card-body">
                                    <Link
                                        className="page-back text-muted"
                                        to={"/signin"}
                                    >
                                        <span>
                                            <i className="fa fa-angle-left"></i>
                                        </span>{" "}
                                        Back
                                    </Link>
                                    <h3 className="text-center">
                                        OTP Verification
                                    </h3>
                                    <p className="text-center mb-5">
                                        We will send one time code on this
                                        number
                                    </p>
                                    <form>
                                        <div className="mb-3">
                                            <label>Your phone number</label>
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text ps-4 pe-4">
                                                        <i className="fa fa-phone"></i>
                                                    </span>
                                                </div>
                                                <input
                                                    readonly={true}
                                                    type="text"
                                                    className="form-control"
                                                    value=  {currentUser && currentUser.phoneNumber} //{phoneNumber}
                                                    //onChange={handlePhoneNumberChange}
                                                    
                                                />
                                            </div>
                                        </div>
                                        <div className="text-center mt-4">
                                            <Link
                                                to={"/otp-2"}
                                                className="btn btn-success btn-block"
                                            >
                                                Send
                                            </Link>
                                        </div>
                                    </form>
                                    <div className="new-account mt-3 d-flex justify-content-between">
                                        <p>
                                            Don't get code?{" "}
                                            <Link
                                                className="text-primary"
                                                to={"/otp-1"}
                                            >
                                                Resend
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Otp1;
