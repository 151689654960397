import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import BtcChart from '../charts/btc';
import DashChart from '../charts/dash';
import EosChart from '../charts/eos';
import EthChart from '../charts/eth';
import LtcChart from '../charts/ltc';
import UsdChart from '../charts/usd';
import XrpChart from '../charts/xrp';
import XtzChart from '../charts/xtz';
import Testimonial from '../element/testimonial';
// import Sidebar from '../layout/sidebar';
// import PageTitle from '../element/page-title';
import Footer1 from '../layout/footer1';
// import { Row, Col, Card } from 'react-bootstrap';
import Header1 from '../layout/header1';
import Bottom from '../element/bottom';
import { useUserAuth } from "../../context/UserAuthContext";



function Homepage() {

    const { LogOut, user } = useUserAuth();
    const navigate = useNavigate();

    const handleLogout = async () => {
      try {
        await LogOut();
        navigate("/");
      } catch (error) {
        console.log(error.message);
      }
    };
    return (
        <>
            <Header1 />


        <div className="intro" id="home">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                <div className="col-xl-7 col-lg-6 col-12">
                    <div className="intro-content">
                    <h1>Grow your wealth with <strong className="text-primary">REON CAPITAL</strong></h1>
                    {/* <p>Your Trusted Partner in Wealth Management</p> */}
                    <p>At REON CAPITAL, we provide you with the tools and expertise to grow and protect your wealth. With a commitment to excellence and a focus on your financial well-being, we're here to help you achieve your investment goals.</p>
                    </div>

                    <div className="intro-btn">
                    {user ? (
                        <>
                        {/* User is logged in */}
                        </>
                    ) : (
                        <>
                        {/* User is not logged in */}
                        <Link to={'/signup'} className="btn btn-primary">Start Investing</Link>
                        </>
                    )}
                    <Link to={'/signin'} className="btn btn-outline-primary">Investment Portal</Link>
                    </div>
                </div>

                <div className="col-xl-5 col-lg-6 col-12">
                    <div className="portfolio_img">
                    <img src={require('./../../images/image-gold.jpg')} alt="Wealth Growth" className="img-fluid" />
                    </div>
                </div>
                </div>
            </div>
            </div>


     <div className="trade-app section-padding"  id="price" >    
        <div className="container">
            <div className="row justify-content-center">
            <div className="col-xl-6">
                <div className="section-title text-center">
                <h2>Invest Wisely with REON CAPITAL</h2>
                <p>Our suite of products is designed to be user-friendly and offers exceptional value for businesses of all kinds.</p>
                </div>
            </div>
            </div>
            <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-12">
                <div className="card trade-app-content">
                <div className="card-body">
                    <span><i className="la la-pagelines"></i></span>
                    <h4 className="card-title">REON Asset Management</h4>
                    <p>Let our experienced asset managers grow your wealth on your behalf. Trust us to maximize your assets.</p>
                    <Link to={'#'}>Learn More <i className="la la-arrow-right"></i></Link>
                </div>
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12">
                <div className="card trade-app-content">
                <div className="card-body">
                    <span><i className="la la-globe"></i></span>
                    <h4 className="card-title">Global Markets</h4>
                    <p>We execute shariah compliant capital market trades on behalf of our clients.</p>
                    <Link to={'#'}>Learn More <i className="la la-arrow-right"></i></Link>
                </div>
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12">
                <div className="card trade-app-content">
                <div className="card-body">
                    <span><i className="la la-chart-pie"></i></span>
                    <h4 className="card-title">Equities</h4>
                    <p>Invest with Reon Capital to own a piece of the financial pie and watch your wealth grow steadily over time.</p>
                    <Link to={'#'}>Learn More <i className="la la-arrow-right"></i></Link>
                </div>
                </div>
            </div>
            </div>

            </div>
        </div>

            
            {/* <div className="row pt-5">
            <div className="col-xl-12">
                <div className="trusted-business py-5 text-center">
                <h3>Trusted by Our <strong>Partners & Investors</strong></h3>
                </div>
                <div className="row justify-content-center">
                <div className="col-auto">
                    <div className="trusted-logo">
                    <Link to={'#'}><img className="img-fluid" src={require('./../../images/brand/1.webp')} alt="Partner Logo 1" /></Link>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="trusted-logo">
                    <Link to={'#'}><img className="img-fluid" src={require('./../../images/brand/2.webp')} alt="Partner Logo 2" /></Link>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="trusted-logo">
                    <Link to={'#'}><img className="img-fluid" src={require('./../../images/brand/3.webp')} alt="Partner Logo 3" /></Link>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="trusted-logo">
                    <Link to={'#'}><img className="img-fluid" src={require('./../../images/brand/4.webp')} alt="Partner Logo 4" /></Link>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="trusted-logo">
                    <Link to={'#'}><img className="img-fluid" src={require('./../../images/brand/5.webp')} alt="Partner Logo 5" /></Link>
                    </div>
                </div>
                </div>
            </div>
            </div> */}
     









        <div className="getstart section-padding" id="portfolio">
        <div className="container">
            <div className="row justify-content-center">
            <div className="col-xl-8">
                <div className="section-title">
                <h2>Invest in Your Future</h2>
                </div>
            </div>
            </div>
            <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                <div className="getstart-content">
                <span><i className="la la-wpforms"></i></span>
                <h3>Get Started with Our Managed Funds</h3>
                <p>Begin your investment journey by registering with one of our three professionally managed investment funds. Whether you're a seasoned investor or new to the world of finance, our funds are designed to cater to your financial goals.</p>
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                <div className="getstart-content">
                <span><i className="la la-user-tie"></i></span>
                <h3>Personalized Account Management</h3>
                <p>At Reon Capital, we believe in the power of personalized service. Once your account has been approved, you'll be assigned a dedicated account manager who will provide tailored guidance and support. Your success is our priority.</p>
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                <div className="getstart-content">
                <span><i className="la la-chart-line"></i></span>
                <h3>Watch Your Wealth Grow Sustainably</h3>
                <p>We take pride in our commitment to ethical and Shariah-compliant investments. Rest assured, your wealth will be invested in opportunities that align with your values while delivering sustainable growth over time. Your financial well-being is our mission.</p>
                </div>
            </div>
            </div>
        </div>
        </div>


            <div className="portfolio section-padding" id="portfolio">
                <div className="container">
                    <div className="row py-lg-5 justify-content-center">
                        <div className="col-xl-7">
                            <div className="section-title text-center">
                                <h2>Create your investment portfolio today</h2>
                                <p>Reon Capital has a variety of features that make it the best place to start vesting in your future</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-xl-7 col-lg-6">
                            <div className="portfolio_list">
                                <div className="row">
                                    <div className="col-xl-6">
                                    <div className="d-flex">
                                            <span className="port-icon"> <i className="la la-bar-chart"></i></span>
                                            <div className="flex-grow-1">
                                                <h4>Manage your Portfolio</h4>
                                                <p> Manage your entire portfolio from the web, just sign up for an online account.
                                            </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="d-flex">
                                            <span className="port-icon"> <i className="la la-calendar-check-o"></i></span>
                                            <div className="flex-grow-1">
                                                <h4>Keep track of your investments</h4>
                                                <p> See where your investments are going, and the returns its yielding.
                                            </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="d-flex">
                                            <span className="port-icon"> <i className="la la-lock"></i></span>
                                            <div className="flex-grow-1">
                                                <h4>Vault protection</h4>
                                                <p>For added security, store your funds in a vault with time delayed
                                                withdrawals.
                                            </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="d-flex">
                                            <span className="port-icon"> <i className="la la-mobile"></i></span>
                                            <div className="flex-grow-1">
                                                <h4>Mobile apps</h4>
                                                <p>Stay on top of the markets with the Reon Capital app for <Link
                                                    to={'#'}>Android</Link>
                                                    <span> and </span>
                                                <Link to={'#'}>iOS</Link>.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xl-5 col-lg-6">
                            <div className="portfolio_img">
                                <img src={require('./../../images/portfolio.png')} alt="" className="img-fluid" />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>




            {/* <div className="testimonial section-padding" id="testimonial">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6">
                            <div className="section-title">
                                <h2>What our customer says</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-10">
                            <div className="testimonial-content">
                                <Testimonial />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="promo section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8">
                            <div className="section-title text-center">
                                <h2>The most trusted cryptocurrency platform</h2>
                                <p> Here are a few reasons why you should choose Reoncapital
                            </p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center py-5">
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="promo-content">
                                <div className="promo-content-img">
                                    <img className="img-fluid" src={require('./../../images/svg/protect.svg')} alt="" />
                                </div>
                                <h3>Secure storage </h3>
                                <p>We store the vast majority of the digital assets in secure offline storage.</p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="promo-content">
                                <div className="promo-content-img">
                                    <img className="img-fluid" src={require('./../../images/svg/cyber.svg')} alt="" />
                                </div>
                                <h3>Protected by insurance</h3>
                                <p>Cryptocurrency stored on our servers is covered by our insurance policy.</p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="promo-content">
                                <div className="promo-content-img">
                                    <img className="img-fluid" src={require('./../../images/svg/finance.svg')} alt="" />
                                </div>
                                <h3>Industry best practices</h3>
                                <p>Reoncapital supports a variety of the most popular digital currencies.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="appss section-padding" id="app">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-xl-7 col-lg-6 col-md-6">
                            <div className="appss-content">
                                <h2>The secure app to store crypto yourself</h2>
                                <ul>
                                    <li><i className="la la-check"></i> All your digital assets in one place</li>
                                    <li><i className="la la-check"></i> Use Decentralized Apps</li>
                                    <li><i className="la la-check"></i> Pay friends, not addresses</li>
                                </ul>
                                <div className="mt-4">
                                    <Link to={'#'} className="btn btn-primary my-1 waves-effect">
                                        <img src={require('./../../images/android.svg')} alt="" />
                                    </Link>
                                    <Link to={'#'} className="btn btn-primary my-1 waves-effect">
                                        <img src={require('./../../images/apple.svg')} alt="" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 col-md-6">
                            <div className="appss-img">
                                <img className="img-fluid" src={require('./../../images/app2.png')} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="blog section-padding" id="blog">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6">
                            <div className="section-title text-center">
                                <h2>Blog</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-12">
                            <div className="blog-grid">
                                <div className="card">
                                    <img className="img-fluid" src={require('./../../images/blog/1.jpg')} alt="" />
                                    <div className="card-body">
                                        <Link href="blog-single.html">
                                            <h4 className="card-title">Why does Litecoin need MimbleWimble?</h4>
                                        </Link>
                                        <p className="card-text">Cras chinwag brown bread Eaton cracking goal so I said a load
                                        of
                                        old tosh baking cakes.!</p>
                                    </div>
                                    <div className="card-footer">
                                        <div className="meta-info">
                                            <Link to={'#'} className="author"><img src={require('./../../images/avatar/5.jpg')} alt="" /> Admin</Link>
                                            <Link to={'#'} className="post-date"><i className="la la-calendar"></i> 31 July, {new Date().getFullYear()} </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-12">
                            <div className="blog-grid">
                                <div className="card">
                                    <img className="img-fluid" src={require('./../../images/blog/2.jpg')} alt="" />
                                    <div className="card-body">
                                        <Link href="blog-single.html">
                                            <h4 className="card-title">How to securely store your HD wallet seeds?</h4>
                                        </Link>
                                        <p className="card-text">Cras chinwag brown bread Eaton cracking goal so I said a load
                                        of
                                        old tosh baking cakes.!</p>
                                    </div>
                                    <div className="card-footer">
                                        <div className="meta-info">
                                            <Link to={'#'} className="author"><img src={require('./../../images/avatar/6.jpg')} alt="" /> Admin</Link>
                                            <Link to={'#'} className="post-date"><i className="la la-calendar"></i> 31 July, {new Date().getFullYear()} </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-12">
                            <div className="blog-grid">
                                <div className="card">
                                    <img className="img-fluid" src={require('./../../images/blog/3.jpg')} alt="" />
                                    <div className="card-body">
                                        <Link href="blog-single.html">
                                            <h4 className="card-title">Exclusive Interview With Xinxi Wang Of Litecoin</h4>
                                        </Link>
                                        <p className="card-text">Cras chinwag brown bread Eaton cracking goal so I said a load
                                        of
                                        old tosh baking cakes.!</p>
                                    </div>
                                    <div className="card-footer">
                                        <div className="meta-info">
                                            <Link to={'#'} className="author"><img src={require('./../../images/avatar/7.jpg')} alt="" /> Admin</Link>
                                            <Link to={'#'} className="post-date"><i className="la la-calendar"></i> 31 July, {new Date().getFullYear()} </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="get-touch section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6">
                            <div className="section-title">
                                <h2>Get in touch. Stay in touch.</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="get-touch-content">
                                <div className="d-flex">
                                    <span><i className="fa fa-shield"></i></span>
                                    <div className="flex-grow-1">
                                        <h4>24 / 7 Support</h4>
                                        <p>Got a problem? Just get in touch. Our support team is available 24/7.
                                    </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="get-touch-content">
                                <div className="d-flex">
                                    <span><i className="fa fa-cubes"></i></span>
                                    <div className="flex-grow-1">
                                        <h4>Reoncapital Blog</h4>
                                        <p>News and updates from the world’s leading cryptocurrency exchange.
                                    </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="get-touch-content">
                                <div className="d-flex">
                                    <span><i className="fa fa-certificate"></i></span>
                                    <div className="flex-grow-1">
                                        <h4>Careers</h4>
                                        <p>Help build the future of technology. Start your new career at Reoncapital.
                                    </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="get-touch-content">
                                <div className="d-flex">
                                    <span><i className="fa fa-life-ring"></i></span>
                                    <div className="flex-grow-1">
                                        <h4>Community</h4>
                                        <p>Reoncapital is global. Join the discussion in our worldwide communities.
                                    </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <Bottom/>

            <Footer1 />
        </>
    )
}

export default Homepage;










