import React from 'react';
import { NavLink } from 'react-router-dom';

function SettingsSubmenu() {
    return (
        <>
            <ul className="d-flex">
                <li className="nav-item">
                    <NavLink
                        to="/settings"
                        activeClassName="active"
                        className="nav-link"
                    >
                        <i className="mdi mdi-account-settings-variant"></i>
                        <span>Edit Profile</span>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        to="/settings-preferences"
                        activeClassName="active"
                        className="nav-link"
                    >
                        <i className="mdi mdi-settings"></i>
                        <span>Preferences</span>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        to="/verification"
                        activeClassName="active"
                        className="nav-link"
                    >
                        <i className="mdi mdi-lock"></i>
                        <span>Account Verification</span>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        to="/settings-account"
                        activeClassName="active"
                        className="nav-link"
                    >
                        <i className="mdi mdi-bank"></i>
                        <span>Linked Account</span>
                    </NavLink>
                </li>
            </ul>
        </>
    )
}

export default SettingsSubmenu;
