import React from 'react';
import { Nav, Tab } from 'react-bootstrap';
import PageTitle from '../element/page-title';
import AccountSubmenu from '../layout/account-submenu';
import Footer2 from '../layout/footer2';
import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import Header2 from '../layout/header2';
import Sidebar from '../layout/sidebar';




function AccountDeposit() {

        // Function to copy input value to clipboard
        const copyToClipboard = (value) => {
            navigator.clipboard.writeText(value);
            // Optionally, you can show a toast message here
            alert('Copied');
        }


    return (
        <>
            <Header2 />
            <Sidebar />
            <PageTitle />

            <div className="content-body">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card sub-menu">
                                <div className="card-body">
                                    <AccountSubmenu />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Deposit to your acount</h4>
                                    <p>To any one of our bank accounts</p>
                                </div>
                                <div className="card-body" id="deposits">
                                    <Tab.Container defaultActiveKey="tab1">
                                        <Nav variant="pills">
                                        {/* <Nav.Link eventKey="tab1">World Remit</Nav.Link>
                                        <Nav.Link eventKey="tab2">Paysii</Nav.Link> */}
                                            <Nav.Link eventKey="tab1">Dahabshiil Bank</Nav.Link>
                                            <Nav.Link eventKey="tab2">Amal Bank</Nav.Link>
                                            <Nav.Link eventKey="tab3">Salaam Bank</Nav.Link>
                                            <Nav.Link eventKey="tab4">Premier Bank</Nav.Link>
                                        </Nav>
                                        <Tab.Content>

                                        {/* <Tab.Pane eventKey="tab1">
                                                <div className="qrcode">
                                                    <img src="./images/qr.svg" alt="" width="150" />
                                                </div>
                                                <form action="">
                                                <label>IBAN</label>
                                                    <div className="input-group">
                                                   
                                                        <input type="text" className="form-control"
                                                            value="SO 33 0001 114 300034772129" />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text bg-primary text-white">Copy</span>
                                                        </div>
                                                    </div>

                                                    <label>Bank Account Number</label>
                                                    <div className="input-group">
                                                   
                                                        <input type="text" className="form-control"
                                                            value="305014994201" />
                                                        <div className="input-group-append">
                                                        <span className="input-group-text bg-primary text-white" onClick={() => copyToClipboard('305014994201')}>Copy</span>
                                                        </div>
                                                    </div>

                                                </form>

                                                <ul>
                                                    <li>
                                                        <i className="mdi mdi-checkbox-blank-circle"></i>
                                                Dahabshiil Bank deposits to your ReonCapital Portfolio account within 24 hours.
                                            </li>
                                                    <li>
                                                        <i className="mdi mdi-checkbox-blank-circle"></i>
                                                        There are no bank deposit limits, and this Dahabshiil Bank deposits to this bank account are unlimited.

                                            </li>
                                                </ul>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="tab2">
                                                <div className="qrcode">
                                                    <img src="./images/qr.svg" alt="" width="150" />
                                                </div>
                                                <form action="">
                                                <label>IBAN</label>
                                                    <div className="input-group">
                                                   
                                                        <input type="text" className="form-control"
                                                            value="SO 33 0001 114 300034772129" />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text bg-primary text-white">Copy</span>
                                                        </div>
                                                    </div>

                                                    <label>Bank Account Number</label>
                                                    <div className="input-group">
                                                   
                                                        <input type="text" className="form-control"
                                                            value="305014994201" />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text bg-primary text-white">Copy</span>
                                                        </div>
                                                    </div>

                                                </form>

                                                <ul>
                                                    <li>
                                                        <i className="mdi mdi-checkbox-blank-circle"></i>
                                                Dahabshiil Bank deposits to your ReonCapital Portfolio account within 24 hours.
                                            </li>
                                                    <li>
                                                        <i className="mdi mdi-checkbox-blank-circle"></i>
                                                        There are no bank deposit limits, and this Dahabshiil Bank deposits to this bank account are unlimited.

                                            </li>
                                                </ul>
                                            </Tab.Pane> */}




                                        <Tab.Pane eventKey="tab1">
                                                <div className="qrcode">
                                                    <img src="./images/qr.svg" alt="" width="150" />
                                                </div>
                                                <form action="">
                                                {/* <label>IBAN</label>
                                                    <div className="input-group">
                                                   
                                                        <input type="text" className="form-control"
                                                            value="SO 33 0001 114 300034772129" />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text bg-primary text-white">Copy</span>
                                                        </div>
                                                    </div> */}

                                                    <label>Bank Account Number</label>
                                                    <div className="input-group">
                                                   
                                                        <input type="text" className="form-control"
                                                            value="305014994201" />
                                                        <div className="input-group-append">
                                                        <span className="input-group-text bg-primary text-white" onClick={() => copyToClipboard('305014994201')}>Copy</span>
                                                        </div>
                                                    </div>

                                                </form>

                                                <ul>
                                                    <li>
                                                        <i className="mdi mdi-checkbox-blank-circle"></i>
                                                Dahabshiil Bank deposits to your ReonCapital Portfolio account within 24 hours.
                                            </li>
                                                    <li>
                                                        <i className="mdi mdi-checkbox-blank-circle"></i>
                                                        There are no bank deposit limits, and this Dahabshiil Bank deposits to this bank account are unlimited.

                                            </li>
                                                </ul>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tab2">
                                                <div className="qrcode">
                                                    <img src="./images/qr.svg" alt="" width="150" />
                                                </div>
                                                <form action="">
                                                {/* <label>IBAN</label>
                                                    <div className="input-group">
                                                   
                                                        <input type="text" className="form-control"
                                                            value="SO 33 0001 114 300034772129" />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text bg-primary text-white">Copy</span>
                                                        </div>
                                                    </div> */}

                                                    <label>Bank Account Number</label>
                                                    <div className="input-group">
                                                   
                                                        <input type="text" className="form-control"
                                                            value="510104964406" />
                                                        <div className="input-group-append">
                                                        <span className="input-group-text bg-primary text-white" onClick={() => copyToClipboard('510104964406')}>Copy</span>
                                                        </div>
                                                    </div>

                                                </form>

                                                <ul>
                                                    <li>
                                                        <i className="mdi mdi-checkbox-blank-circle"></i>
                                                Amal Bank deposits to your ReonCapital Portfolio account within 24 hours.
                                            </li>
                                                    <li>
                                                        <i className="mdi mdi-checkbox-blank-circle"></i>
                                                        There are no bank deposit limits, and this Amal Bank deposits to this bank account are unlimited.

                                            </li>
                                                </ul>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tab3">
                                                <div className="qrcode">
                                                    <img src="./images/qr.svg" alt="" width="150" />
                                                </div>
                                                <form action="">
                                                <label>IBAN</label>
                                                    <div className="input-group">
                                                   
                                                        <input type="text" className="form-control"
                                                            value="SO 58 0001 114 300037161565" />
                                                        <div className="input-group-append">
                                                        <span className="input-group-text bg-primary text-white" onClick={() => copyToClipboard('SO 58 0001 114 300037161565')}>Copy</span>
                                                        </div>
                                                    </div>

                                                    <label>Bank Account Number</label>
                                                    <div className="input-group">
                                                   
                                                        <input type="text" className="form-control"
                                                            value="37161565" />
                                                        <div className="input-group-append">
                                                        <span className="input-group-text bg-primary text-white" onClick={() => copyToClipboard('37161565')}>Copy</span>
                                                        </div>
                                                    </div>

                                                </form>

                                                <ul>
                                                    <li>
                                                        <i className="mdi mdi-checkbox-blank-circle"></i>
                                                Salaam Bank deposits to your ReonCapital Portfolio account within 24 hours.
                                            </li>
                                                    <li>
                                                        <i className="mdi mdi-checkbox-blank-circle"></i>
                                                        There are no bank deposit limits, and this Salaam Bank deposits to this bank account are unlimited.

                                            </li>
                                                </ul>
                                            </Tab.Pane>







                                            <Tab.Pane eventKey="tab4">
                                                <div className="qrcode">
                                                    <img src="./images/qr.svg" alt="" width="150" />
                                                </div>
                                                <form action="">
                                                {/* <label>IBAN</label>
                                                    <div className="input-group">
                                                   
                                                        <input type="text" className="form-control"
                                                            value="SO 33 0001 114 300034772129" />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text bg-primary text-white">Copy</span>
                                                        </div>
                                                    </div> */}

                                                    <label>Bank Account Number</label>
                                                    <div className="input-group">
                                                   
                                                        <input type="text" className="form-control"
                                                            value="060608359001" />
                                                        <div className="input-group-append">
                                                        <span className="input-group-text bg-primary text-white" onClick={() => copyToClipboard('060608359001')}>Copy</span>
                                                        </div>
                                                    </div>

                                                </form>

                                                <ul>
                                                    <li>
                                                        <i className="mdi mdi-checkbox-blank-circle"></i>
                                                Premier Bank deposits to your ReonCapital Portfolio account within 24 hours.
                                            </li>
                                                    <li>
                                                        <i className="mdi mdi-checkbox-blank-circle"></i>
                                                        There are no bank deposit limits, and this Premier Bank deposits to this bank account are unlimited.

                                            </li>
                                                </ul>
                                            </Tab.Pane>






                                        </Tab.Content>

                                    </Tab.Container>
                                </div>
                            </div>
                        </div>


                <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Direct Deposit section coming soon</h4>
                                </div>
                                <div className="card-body">
                                    <div className="important-info">
                                        <h6>Soon you'll be able to...</h6>
                                        <ul>
                                            <li>
                                                <i className="mdi mdi-checkbox-blank-circle"></i>
                                                Link your bank account to your portfolio account
                                            
                                        </li>
                                            <li>
                                                <i className="mdi mdi-checkbox-blank-circle"></i>
                                       Link your Debit Card to your portfolio account
                                        </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <Footer2 />
        </>
    )
}

export default AccountDeposit;


