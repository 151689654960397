import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useUserAuth } from "../../context/UserAuthContext";
import {capitalizeWords} from '../element/utils';




function PageTitle() {
    const { user } = useUserAuth();
    const location = useLocation();

    // Function to determine if a route matches the current location
    const isRouteActive = (route) => {
        return location.pathname === route ? 'active' : '';
    };

        // Define a variable to hold the color based on the user's display name
        const statusBarColor = user.displayName === "Red" ? "red" : "transparent";

    return (


      
   
  


        <div className="page-title dashboard">
        {/* <div style={{ width: '100%', height: '90px', backgroundColor: 'red', marginLeft: '50px' }}> */}
        <div style={{  backgroundColor: statusBarColor, marginLeft: '100px',  marginRight: '100px' }}>
            <div className="container">
                <div className="row">
                    <div className="col-6">
                        <div className="page-title-content">
                        <div class = "container-fluid">



                </div>

                            <p>
                                Welcome Back,
                                <span> {user && capitalizeWords(user.displayName)}</span>
                            </p>
                        </div>
                    </div>
                    <div className="col-6">
                        <ul className="text-end breadcrumbs list-unstyled">
                        <li className={isRouteActive('/account-overview') || isRouteActive('/account-deposit') || isRouteActive('/account-withdraw') ? 'active' : ''}>
                                <Link to="/account-overview">Account Overview</Link>
                            </li>                           
                            <li className={isRouteActive('/settings') || isRouteActive('/settings-preferences') || isRouteActive('/verification')  || isRouteActive('/settings-account')  ? 'active' : ''}>
                                <Link to="/settings">Settings</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

// function capitalizeWords(str) {
//     if (str) {
//         return str
//             .split(' ')
//             .map(word => word.charAt(0).toUpperCase() + word.slice(1))
//             .join(' ');
//     } else {
//         return '';
//     }
// }





export default PageTitle;
