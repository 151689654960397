import React from 'react';
import { NavLink } from 'react-router-dom';

function AccountSubmenu() {
    return (
        <ul className="d-flex">
            <li className="nav-item">
                <NavLink to="/account-overview" activeClassName="active" className="nav-link">
                    <i className="mdi mdi-bullseye"></i>
                    <span>Account Overview</span>
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/account-deposit" activeClassName="active" className="nav-link">
                    <i className="mdi mdi-heart"></i>
                    <span>Deposit</span>
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/account-withdraw" activeClassName="active" className="nav-link">
                    <i className="mdi mdi-pentagon"></i>
                    <span>Withdraw</span>
                </NavLink>
            </li>
            {/* <li className="nav-item">
                <NavLink to="/account-api" activeClassName="active" className="nav-link">
                    <i className="mdi mdi-database"></i>
                    <span>API</span>
                </NavLink>
            </li> */}
            {/* <li className="nav-item">
                <NavLink to="/account-affiliate" activeClassName="active" className="nav-link">
                    <i className="mdi mdi-diamond"></i>
                    <span>Affiliate</span>
                </NavLink>
            </li> */}
        </ul>
    )
}

export default AccountSubmenu;
