import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot from react-dom/client
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { UserAuthContextProvider } from "./context/UserAuthContext";

const root = document.getElementById('root'); // Get the root element

// Use createRoot to render your React application
const reactRoot = createRoot(root);

reactRoot.render(
  <React.StrictMode>
    <UserAuthContextProvider> 
    <App />
    </UserAuthContextProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
