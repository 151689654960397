import React from "react";
import { Link } from "react-router-dom";

function TermsAndConditions() {
  return (
    <div className="container my-5">
      <div className="text-center">
        <Link to="/">
          <img
            src={require("../../images/reoncapital_logo_transparent_small_small_v2.png")}
            alt="Reon Capital"
            className="mb-5"
          />
        </Link>
        <h3 className="mb-4">Reon Capital Terms and Conditions</h3>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header text-center">
              <h4 cdiv className="card-body text-center"> Funds Objective</h4>
            </div>
            <div className="card-body text-center">
              <p>
                The primary objective is long term capital appreciation by
                investing equity securities to gain capital growth. These funds
                are specifically designed to optimize returns for clients
                through vetted assets classes.
              </p>
              <h5>Types of Funds:</h5>
              <ul className="text-left">
                <li>
                  <strong>AMANA:</strong> Minimum of $100
                  monthly subscription, no maximum increase, no decrease during
                  the lock-in period (once the monthly subscription is
                  increased, it cannot be decreased to the initial subscription
                  amount i.e. $100).
                </li>
                <li>
                  <strong>AMANA Gold:</strong> Available to all
                  investors, minimum of $15000 initial investment, no
                  subscription, can increase initial investment ($10000 minimum
                  requirement).
                </li>
                <li>
                  <strong>AMANA Platinum:</strong> Available to all
                  investors, minimum of $100000 initial investment, no
                  subscription, can increase initial investment ($500000 minimum
                  requirement).
                </li>
              </ul>
              <p>
                Timing of investment schedule – All subscribed investment must
                be paid on the 1st of the month. All funds have a lock-in
                period of 2 years minimum.
              </p>
              <h5>Penalties:</h5>
              <ul className="text-left">
                <li>
                  <strong>AMANA:</strong> Early withdrawal before
                  the end of the lock-in period can result in 25% of management
                  fees deduction of your capital investment.
                </li>
                <li>
                  <strong>Non-payment:</strong> Missed payment and payment
                  received after the 7th of each month will result in being put
                  on notice which can lead to further action of liquidating your
                  capital investment and deduction of 25% of management fees.
                </li>
                <li>
                  <strong>AMANA Gold:</strong> Withdrawal request for this
                  fund before the end of lock-in period (2 years) will result in
                  liquidation of your capital investment and deduction of 25% of
                  management fees.
                </li>
                <li>
                  <strong>AMANA Platinum:</strong> Withdrawal request for
                  this fund before the end of lock-in period will result in
                  liquidating your capital investment and deduction of 25% of
                  management fees.
                </li>
              </ul>
              <p>
                <strong>Annual Management Fees:</strong> All investments
                attract a 5% management fee plus 15% performance fees for
                capital investment less than 15%. All capital investments
                greater than or equal to 20% attract a 20% performance fee.
              </p>
              <h5>Types of Asset Investments:</h5>
              <ul className="text-left">
                <li>Fixed income</li>
                <li>
                  Commodities such as energy, water, infrastructure,
                  and telecommunications
                </li>
                <li>Equity – Capital growth through diversification</li>
                <li>Future Markets – Plans to invest in E-commerce and technology through emerging markets.</li>
              </ul>
              <h5>Disclosure</h5>
              <p>
                Investing in alternative funds may not be suitable for all
                types of investors. Before deciding to invest, consider your
                investment objectives, duration, and risk assessment. Markets
                are complex, and investments can increase as well as decrease.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
