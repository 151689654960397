import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageTitle from '../element/page-title';
import Footer2 from '../layout/footer2';
// import { Row, Col, Card } from 'react-bootstrap';
import Header2 from '../layout/header2';
import SettingsSubmenu from '../layout/settings-submenu';
import Sidebar from '../layout/sidebar';
import { useUserAuth } from "../../context/UserAuthContext";
import {capitalizeWords} from '../element/utils';



function Verification() {
    const { getClient, user, transactions } = useUserAuth();
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState(null); // State to hold the user data


    useEffect(() => {
        // Fetch the user data when the component mounts
        async function fetchUserData() {
            try {
                const userData  = await getClient(user.uid);
                setCurrentUser(userData);
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        }

        if (user) {
            fetchUserData();
           
           
        }
    }, [getClient, user]);

    return (
        <>
        {currentUser ? (

        <>
            <Header2 />
            <Sidebar />
            <PageTitle />

            <div className="content-body">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card sub-menu">
                                <div className="card-body">
                                    <SettingsSubmenu />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Verification</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row align-items-center">
                                        <div className="col-xl-4">
                                            <div className="id_card">
                                                <img src={require("../../images/id.png")} alt="" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="id_info">
                                                <h4> { currentUser.title + " "+ capitalizeWords(currentUser.fullName) } </h4>
                                                <p className="mb-1 mt-3">ID: 0024 5687 2254 3698 </p>
                                                <p className="mb-1">Status: <span className="font-weight-bold">Not-Verified</span></p>
                                                <Link to="/verify-step-2" className="btn btn-success mt-3">Upload ID</Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="phone_verify">
                                                <h4 className="card-title mb-3">Email Address</h4>
                                                <form action="/otp-2">
                                                    <div className="row align-items-center">
                                                        <div className="mb-3 col-xl-5">
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder=  {currentUser.email} />
                                                                <div className="input-group-append">
                                                                    <button className=" btn input-group-text bg-primary text-white">Add</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-xl-12">
                                            <div className="phone_verified">
                                                <h5> <span><i className="fa fa-envelope"></i></span> {currentUser.email}  </h5>
                                                <div className="verify">
                                                    <div className="verified">
                                                        <span><i className="la la-check"></i></span>
                                                        <Link to={""}>Verified</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="phone_verify">
                                                <h4 className="card-title mb-3">Phone Number </h4>
                                                <form action="/otp-1">
                                                    <div className="row align-items-center">
                                                        <div className="mb-3 col-xl-5">
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder={currentUser.phoneNumber} />
                                                                <div className="input-group-append">
                                                                    <button className=" btn input-group-text bg-primary text-white">Add</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-xl-12">
                                            <div className="phone_verified">
                                                <h5> <span><i className="fa fa-phone"></i></span> {currentUser.phoneNumber} </h5>
                                                <div className="verify">
                                                    <div className="verified">
                                                        <span><i className="la la-check"></i></span>
                                                        <Link to={""}>Verified</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer2 />
        </>
        ) : (
            <>
                {/* <Link to="/signin" className="btn btn-primary ms-3">
                <i className="mdi mdi-login"></i> Log In
                </Link> */}

                <div className="content-body">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="card sub-menu">
                                                <div className="card-body">
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                             </div>




            </>
            )}
    </>
    );
}

export default Verification;