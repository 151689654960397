import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import ScrollspyNav from "react-scrollspy-nav";
import { useUserAuth } from "../../context/UserAuthContext";



function Header1() {

    const { LogOut, user } = useUserAuth();
    const navigate = useNavigate();

    const handleLogout = async () => {
      try {
        await LogOut();
        navigate("/");
      } catch (error) {
        console.log(error.message);
      }
    };

    return (
        <>
            <div className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="navigation">
                                <Navbar bg="light" expand="lg">
                                    <Link className="navbar-brand" to={'/'}><img src={require('./../../images/reoncapital_logo_transparent_small_small_v2.png')} alt="" /></Link>
                                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                    <Navbar.Collapse>
                                        <ScrollspyNav
                                            scrollTargetIds={["home" ]} // ,  "price", "portfolio", "testimonial", "app", "blog",]}
                                            offset={100}
                                            activeNavclassName="active"
                                            scrollDuration="1000"
                                            headerBackground="true"
                                        >
                                            <Nav className="ms-auto">
                                                <Nav.Item><Nav.Link className="nav-Nav.link" href="#home">Home</Nav.Link></Nav.Item>

                                         

                                               
{/* 

                                                <Nav.Item><Nav.Link className="nav-Nav.link" href="#price">Price</Nav.Link></Nav.Item>
                                                <Nav.Item><Nav.Link className="nav-Nav.link" href="#portfolio">Portfolio </Nav.Link></Nav.Item>
                                                <Nav.Item><Nav.Link className="nav-Nav.link" href="#testimonial">Testimonial</Nav.Link></Nav.Item>
                                                <Nav.Item><Nav.Link className="nav-Nav.link" href="#app">App</Nav.Link></Nav.Item>
                                                <Nav.Item><Nav.Link className="nav-Nav.link" href="#blog">Blog</Nav.Link></Nav.Item> */}
                                            </Nav>
                                        </ScrollspyNav>

                                        <Nav>

                                        {/* <Nav.Item><Nav.Link className="nav-Nav.link" href="/aboutus">About Us</Nav.Link></Nav.Item> */}

                                        </Nav>

                                    </Navbar.Collapse>

                                    <div className="signin-btn">
                                       
                                 
                                    {user ? (
                                        <>
                                            <Link to="/account-overview">My Account</Link>
                                            <Link to="/" className="btn btn-primary ms-3" onClick={handleLogout}>
                                            <i className="mdi mdi-logout"></i> Logout
                                            </Link>
                                        </>
                                        ) : (
                                        <>
                                            <Link to="/signin" className="btn btn-primary ms-3">
                                            <i className="mdi mdi-login"></i> Log In
                                            </Link>
                                        </>
                                        )}



                                    </div>
                                </Navbar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header1;