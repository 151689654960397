import React, { useEffect, useState } from "react";
import { Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import {capitalizeWords} from '../element/utils';
import { useUserAuth } from "../../context/UserAuthContext";

const ProfileToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}

    >
        {children}
        <div className="profile_log">
            <div className="user">
                <span className="thumb"><i className="mdi mdi-account"></i></span>
                <span className="arrow"><i className="la la-angle-down"></i></span>
            </div>
        </div>
    </div>
));

const LanguageToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <div className="language">
            <div className="icon">
                <i className="flag-icon flag-icon-gb"></i>
                <span>English</span>
            </div>
        </div>
    </div>
));




function Header2() {
    const { LogOut, getClient, user, transactions  } = useUserAuth();
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState(null); // State to hold the user data
    const [selectedLanguage, setSelectedLanguage] = useState('en'); // Default language is English

    useEffect(() => {
        // Fetch the user data when the component mounts
        async function fetchUserData() {
            try {
                const userData  = await getClient(user.uid);
                setCurrentUser(userData);
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        }

        if (user) {
            fetchUserData();
           
           
        }
    }, [getClient, user]);



    const handleLanguageChange = (languageCode) => {
        // Implement logic to change the language
        // For example, you can use i18n libraries to handle translations
        setSelectedLanguage(languageCode);
    };


    const handleLogout = async () => {
      try {
        await LogOut();
        navigate("/");
      } catch (error) {
        console.log(error.message);
      }
    };

    return (
        <>
            <div className="header dashboard">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <nav className="navbar navbar-expand-lg navbar-light px-0 justify-content-between">
                                <Link className="navbar-brand" to={"/"}><img src={require('./../../images/reoncapital_logo_transparent_small_small_v2.png')} alt="" /></Link>

                                <div className="header-right d-flex my-2 align-items-center">
                                    <div className="language">
                                        <Dropdown>
                                            <Dropdown.Toggle as={LanguageToggle} />
                                            <Dropdown.Menu size="sm" title="">
                                                <Link
                                                    to={'#'}
                                                    className="dropdown-item"
                                                    onClick={() => handleLanguageChange('so')}
                                                >
                                                    <i className="flag-icon flag-icon-so"></i> Somali
                                                </Link>

                                                {/* <Link
                                                    to={'#'}
                                                    className="dropdown-item"
                                                    onClick={() => handleLanguageChange('en')}
                                                >
                                                    <i className="flag-icon flag-icon-sa"></i> Arabic
                                                </Link> */}


                                            {/* <Link to={'#'} className="dropdown-item">
                                                    <i className="flag-icon flag-icon-cn"></i> China
                                            </Link> */}

                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="dashboard_log">
                                        <Dropdown className="profile_log">
                                            <Dropdown.Toggle as={ProfileToggle} />
                                            <Dropdown.Menu size="sm" title="">
                                                <div className="user-email">
                                                    <div className="user">
                                                        <span className="thumb"><i className="mdi mdi-account"></i></span>
                                                        <div className="user-info">

                                                            <h6>   {user && capitalizeWords(user.displayName) } </h6>
                                                            <span>  {user && user.email}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="user-balance">
                                                    <div className="available">
                                                        <p>Available</p>
                                                        <span>$0.00</span>
                                                    </div>
                                                    <div className="total">
                                                        <p>Invested</p>
                                                        <span>  {`$${currentUser && currentUser.accountBalance } `} </span>
                                                    </div>
                                                </div>

                                                <Link to={"/account-overview"} className="dropdown-item">
                                                    <i className="mdi mdi-account"></i> Account
                                            </Link>




                                                {/* <Link to={"/data-tbi"} className="dropdown-item">
                                                    <i className="mdi mdi-history"></i> History
                                            </Link> */}
                                                <Link to={"/settings"} className="dropdown-item">
                                                    <i className="mdi mdi-settings"></i> Setting
                                            </Link>
                                            {/* <Link to={"/lock"} className="dropdown-item">
                                                    <i className="mdi mdi-lock"></i> Lock
                                            </Link> */}

                                            <button onClick={handleLogout} className="dropdown-item logout"> <i className="mdi mdi-logout"></i> Logout</button>

                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header2;