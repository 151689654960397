import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../element/page-title";
import AccountSubmenu from "../layout/account-submenu";
import Footer2 from "../layout/footer2";
// import { Row, Col, Card } from 'react-bootstrap';
import Header2 from "../layout/header2";
import Sidebar from "../layout/sidebar";
import {capitalizeWords} from '../element/utils';
import { useUserAuth } from "../../context/UserAuthContext";


function AccountOverview() {
    const { getClient, user, transactions } = useUserAuth();
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState(null); // State to hold the user data

    useEffect(() => {
        // Fetch the user data when the component mounts
        async function fetchUserData() {
            try {
                const userData  = await getClient(user.uid);
                setCurrentUser(userData);
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        }

        if (user) {
            fetchUserData();
           
           
        }
    }, [getClient, user]);
   
    // console.error("loginDateTime:",   currentUser.loginDateTime.toDate().toDateString() );
    // console.error("loginDateTime:",    new Date(currentUser.loginDateTime / 1000000) );
    return (
        <>
        {currentUser ? (
        <>
            <Header2 />
            <Sidebar />
            <PageTitle />

            <div className="content-body">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card sub-menu">
                                <div className="card-body">
                                    <AccountSubmenu />
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="card profile_card">
                                <div className="card-body">
                                    <div className="d-flex">
                                        {/* <img
                                            className="me-3 rounded-circle me-0 me-sm-3"
                                            src={require("../../images/profile/2.png")}
                                            width="60"
                                            height="60"
                                            alt=""
                                        /> */}

                                        
<i class="la la-user la-4x"></i>



                                        <div className="media-body">
                                            <span>Hello</span>
                                            <h4 className="mb-2"> {currentUser.title}   {   capitalizeWords(currentUser.firstName) } </h4>
                                            <p className="mb-1">
                                                {" "}
                                                <span>
                                                    <i className="fa fa-phone me-2 text-primary"></i>
                                                </span>{" "}
                                                {currentUser.phoneNumber}
                                            </p>
                                            <p className="mb-1">
                                                {" "}
                                                <span>
                                                    <i className="fa fa-envelope me-2 text-primary"></i>
                                                </span>
                                                {currentUser.email}
                                            </p>
                                        </div>
                                    </div>

                                    <ul className="card-profile__info">
                                        <li>
                                            <h5 className="me-4">Address</h5>
                                            <span className="text-muted" style={{ fontSize: '13px' }}  >
                                            {currentUser.address1 + ' ' + currentUser.address2 + ' ' + currentUser.addressCity + ' '  +  currentUser.addressPostCode +  ' '  +currentUser.addressCountry  }
                                            </span>
                                        </li>
                                        <li className="mb-1">
                                            <h5 className="me-4">Total Logins</h5>
                                            <span>
                                               {currentUser.noOfLogins}
                                            </span>
                                        </li>
                                        <li>
                                            <h5 className="text-danger me-4">
                                                Last Login
                                            </h5>
                                            <span className="text-danger">
                                                {/* 3 February,{" "}  {new Date().getFullYear()} , 10:00 PM */}
                                            { currentUser.loginDateTime!=null && currentUser.loginDateTime.toDate().toDateString() }
                                            </span>
                                        </li>
                                    </ul>
                                    <div className="social-icons">
                                    <Link
                                        className="instagram text-center"
                                        to={"https://instagram.com/reoncapital"}
                                        >
                                        <span>
                                            <i className="fa fa-instagram"></i>
                                        </span>
                                    </Link>

                                    <Link
                                        className="facebook text-center"
                                        to={"https://facebook.com/reoncapital"}
                                        >
                                        <span>
                                            <i className="fa fa-facebook"></i>
                                        </span>
                                    </Link>
                                    <Link
                                        className="twitter text-center"
                                        to={"https://twitter.com/reoncapital"}
                                        >
                                        <span>
                                            <i className="fa fa-twitter"></i>
                                        </span>
                                    </Link>
                                    <Link
                                        className="youtube text-center"
                                        to={"https://youtube.com/reoncapital"}
                                        >
                                        <span>
                                            <i className="fa fa-youtube"></i>
                                        </span>
                                    </Link>

     
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="card acc_balance">
                                <div className="card-header">
                                    <h4 className="card-title">Fund Details</h4>
                                </div>
                                <div className="card-body">
                                    <span>Subscribed to</span>
                                    <h3>   {currentUser.fundX['fundName']}  </h3>

                                    <div className="d-flex justify-content-between my-3">
                                        <div>
                                            <p className="mb-1">Investment Total</p>
                                            <h4> {`$${currentUser.fundX['fundTotalInvestment']} `} </h4>
                                        </div>
                                        <div>
                                            <p className="mb-1">Currently Invested</p>
                                            <h4>  {`$${currentUser.accountBalance} `}  </h4>
                                        </div>
                                        <div>
                                            <p className="mb-1">Payment Amount Outstanding</p>
                                            <h4> {`$${currentUser.fundX['fundTotalInvestment'] - currentUser.accountBalance} `} </h4>
                                        </div>
                                    </div>
                                    

                                     <div className="d-flex justify-content-between my-3">
                                     <div>
                                            <p className="mb-1">Maturity period</p>
                                            <h4> {`${currentUser.fundX['fundMaturityMonths']} Months`}</h4>
                                        </div>

                                        <div>
                                            <p className="mb-1">Lock-in period</p>
                                            <h4> {`${currentUser.fundX['fundLockPeriodMonths']} Months`}</h4>
                                        </div>

                                        <div>
                                            <p className="mb-1">Payment Months Outstanding</p>
                                            <h4>   {`${ currentUser.fundX['fundLockPeriodMonths'] - currentUser.fundX['fundPaymentMonthNo']} Months`}  </h4>
                                        </div>
                                    </div>

                                    {/* <div className="btn-group mb-3">
                                        <button className="btn btn-success">
                                            Buy
                                        </button>
                                        <button className="btn btn-danger">
                                            Sell
                                        </button>
                                    </div>  */}

                                </div>
                            </div>
                        </div>

                        {/* <div className="col-xl-12">
                            <div className="row">
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                                    <div className="card text-center pt-2">
                                        <div className="card-body">
                                            <p className="mb-1">Maintainance</p>
                                            <h4>0.03654 BTC</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                                    <div className="card text-center pt-2">
                                        <div className="card-body">
                                            <p className="mb-1">Unrealized P&L</p>
                                            <h4>0.03654 BTC</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                                    <div className="card text-center pt-2">
                                        <div className="card-body">
                                            <p className="mb-1">Open Positions</p>
                                            <h4>0.03654 BTC</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                                    <div className="card text-center pt-2">
                                        <div className="card-body">
                                            <p className="mb-1">Active Orders</p>
                                            <h4>0.03654 BTC</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">
                                        Transactions History
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <div className="transaction-table">
                                        <div className="table-responsive">
                                        <table className="table table-striped mb-0 table-responsive-sm">
                                        <thead>
                                            <tr>
                                            <th>Transaction ID</th>
                                            <th>Date</th>
                                            <th>Type</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                            <th>Balance</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {transactions.map((transaction) => (
                                            <tr key={transaction.id}>
                                                <td>{transaction.id}</td>
                                                <td>
                                                { transaction .transactionDateTime!=null &&  transaction .transactionDateTime.toDate().toLocaleDateString("en-GB", {
                                                    day: "numeric",
                                                    month: "long",                                                 
                                                    year: "numeric",
                                                })}
                                                </td>
                                                <td>{transaction.type}</td>
                                                <td className={transaction.type.toLowerCase() === "deposit" ? "text-success" : "text-danger"}>  {transaction.amount} {transaction.currency} </td>
                                                <td className={transaction.status.toLowerCase() === "completed" ? "text-success" : "text-danger"}>  {transaction.status} </td>
                                                
                                            
                                                <td>{transaction.balance} {transaction.currency}</td>
                                            </tr>
                                            ))}
                                        </tbody>
                                        </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer2 />
        </>
        ) : (
            <>
                {/* <Link to="/signin" className="btn btn-primary ms-3">
                <i className="mdi mdi-login"></i> Log In
                </Link> */}






            </>
            )}
    </>
    );
}

export default AccountOverview;
