import React from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useLocation } from 'react-router-dom';

const home = (
    <Tooltip id="home">
        Home
    </Tooltip>
);
const exchange = (
    <Tooltip id="exchange">
        Exchange
    </Tooltip>
);
const account = (
    <Tooltip id="accounts">
        Account
    </Tooltip>
);
const data = (
    <Tooltip id="data">
        Data
    </Tooltip>
);
const settings = (
    <Tooltip id="settings">
        Settings
    </Tooltip>
);

// Function to determine if a route matches the current location
const isRouteActive = (route, location) => {
    return location.pathname === route ? 'active' : '';
};

function Sidebar() {
    const location = useLocation();

    return (
        <>
            <div className="sidebar">
                <div className="menu">
                    <ul>
                        {/* <li>
                            <Link to={"/dashboard"} activeClassName="active">
                                <OverlayTrigger placement="right" overlay={home}>
                                    <span className={isRouteActive('/dashboard', location)}><i className="mdi mdi-view-dashboard"></i></span>
                                </OverlayTrigger>
                            </Link>
                        </li>
                        <li>
                            <Link to={"/exchange"} activeClassName="active">
                                <OverlayTrigger placement="right" overlay={exchange}>
                                    <span className={isRouteActive('/exchange', location)}><i className="mdi mdi-tumblr-reblog"></i></span>
                                </OverlayTrigger>
                            </Link>
                        </li> */}
                        <li>
                            <Link to={"/account-overview"} activeClassName="active">
                                <OverlayTrigger placement="right" overlay={account}>
                                    <span className={isRouteActive('/account-overview', location)}><i className="mdi mdi-face-profile"></i></span>
                                </OverlayTrigger>
                            </Link>
                        </li>
                        {/* <li>
                            <Link to={"/data-tbi"} activeClassName="active">
                                <OverlayTrigger placement="right" overlay={data}>
                                    <span className={isRouteActive('/data-tbi', location)}><i className="mdi mdi-database"></i></span>
                                </OverlayTrigger>
                            </Link>
                        </li> */}
                        <li>
                            <Link to={"/settings"} activeClassName="active">
                                <OverlayTrigger placement="right" overlay={settings}>
                                    <span className={isRouteActive('/settings', location)}><i className="mdi mdi-settings"></i></span>
                                </OverlayTrigger>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Sidebar;
