import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert, Button } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimeDatePicker from '../element/datepicker';
import DatePicker from "react-datepicker";
import ReactFlagsSelect from "react-flags-select";
// import { DateSelect } from "react-ymd-date-select/dist/cjs/presets/mui";

import { ChakraProvider } from "@chakra-ui/react";
// import { DateSelect } from "react-ymd-date-select/dist/cjs/presets/chakra-ui";


import { DateSelect } from "react-ymd-date-select/dist/cjs/presets/mui";

import Slider from "@mui/material/Slider";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber,isPossiblePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'



import { useUserAuth } from "../../context/UserAuthContext";

function Signup() {
  const {UserSignUp} = useUserAuth();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [date, setDate] = useState("");


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");

  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressPostCode, setAddressPostCode] = useState("");
  const [addressCountry, setAddressCountry] = useState("");
  const [dob, setDob] = useState("");

  const [dobDay, setDobDay] = useState("");
  const [dobMonth, setDobMonth] = useState("");
  const [dobYear, setDobYear] = useState("");


  const [nationality, setNationality] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  
  const [fundName, setFundName] = useState("");
  const [fundMonthlyAmount, setfundMonthlyAmount] = useState(450);
  const [fundTotalAmountInvested, setfundTotalAmountInvested] = useState(fundMonthlyAmount * 24);
  

  // Regular expression pattern for a valid phone number (e.g., +1234567890)
  const phonePattern = /^[+]\d{1,}$/;



// handleDropDownChanges
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const handleDobDayChange = (e) => {
    setDobDay(e.target.value);
  };

  const handleDobMonthChange = (e) => {
    setDobMonth(e.target.value);
  };

  const handleDobYearChange = (e) => {
    setDobYear(e.target.value);
  };
  




  const handleFundNameChange = (e) => {
    setFundName(e.target.value);
  };

  // Define a function to handle country selection
  const handleAddressCountrySelect = (code) => {
    setAddressCountry(code);
  };

  const handleNationalitySelect = (code) => {
    setNationality(code);
  };

  const formattedfundTotalAmountInvested = fundTotalAmountInvested.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0, // Remove decimal places
  });

  const handlefundMonthlyAmountChange = (newValue) => {
    setfundMonthlyAmount(newValue);
    setfundTotalAmountInvested(newValue * 24);
  };  





  //Save form Data to location Storage when it changes
  // Save form data to local storage when it changes
// useEffect(() => {
//   const formDataToSave = {
//     email,
//     password,
//     title,
//     firstName,
//     middleName,
//     lastName,
//     gender,
//     address1,
//     address2,
//     addressCity,
//     addressPostCode,
//     addressCountry,
//     dob,
//     dobDay,
//     dobMonth,
//     dobYear,
//     nationality,
//     phoneNumber,
//     fundName,
//     fundMonthlyAmount,
//     fundTotalAmountInvested,
//   };
//   console.log("useEffect is being called");
//   localStorage.setItem("signupFormData", JSON.stringify(formDataToSave));
// }, [email, password, title, firstName, middleName, lastName, gender, address1, address2, addressCity, addressPostCode, addressCountry, dob, dobDay, dobMonth, dobYear, nationality, phoneNumber, fundName, fundMonthlyAmount, fundTotalAmountInvested]);




  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = []; // Create an array to store errors

          // Form Validations
          if (!title) {
            errors.push("Enter Title");
          }

          if (!firstName) {
            errors.push("Enter First Name");
          }

          if (!middleName) {
            errors.push("Enter Middle Name");
          }

          if (!lastName) {
            errors.push("Enter Last Name");
          }

          if (!gender) {
            errors.push("Select Gender");
          }


          if (!dobDay || !dobMonth || !dobYear) {
            errors.push("Enter Date of Birth");
          }

          if (dobYear >  new Date().getFullYear() - 18  ) {
            errors.push("You must be 18 and over");
          }

          if (!phoneNumber) {
            errors.push("Enter Phone Number");
          }



          if( phoneNumber && !isValidPhoneNumber(phoneNumber)  )
          {
            errors.push("Enter Complete Phone Number");
          }

          if (!nationality) {
            errors.push("Enter Nationality");
          }



          if (!address1) {
            errors.push("Enter Address Line 1");
          }

          // if (!address2) {
          //   errors.push("Enter Address Line 2");
          // }

          if (!addressCity) {
            errors.push("Enter City");
          }

          if (!addressPostCode) {
            errors.push("Enter Post Code");
          }

          if (!addressCountry) {
            errors.push("Enter Country");
          }


          if (fundMonthlyAmount === 450 ) {
            errors.push("Enter the monthly amount you want to invest");
          }



          // if (!fundName) {
          //   errors.push("Enter Fund");
          // }

          if (!fundMonthlyAmount || isNaN(fundMonthlyAmount)) {
            errors.push("Enter a valid Monthly Amount");
          }

          if (!fundTotalAmountInvested || isNaN(fundTotalAmountInvested)) {
            errors.push("Enter a valid Total Amount Invested");
          }
              
          // Check if there are any errors
          if (errors.length > 0) {
            setError(errors.join('\n')); // Join errors with newlines and set the error message
            return;
          }



    try {

      var fullName = firstName.trim() + " " + middleName.trim() + " " + lastName.trim()
      var _dob = dobDay + '-' + dobMonth + '-' + dobYear;
      var _fundName = 'Amana Fund';

        await UserSignUp(email, password, fullName, title, firstName, middleName, lastName
          , gender
          , _dob
          ,phoneNumber  , nationality
          ,address1, address2, addressCity, addressPostCode, addressCountry
          ,_fundName ,fundMonthlyAmount, fundTotalAmountInvested

            ); // Call SignIn function correctly
      // Call your signup function here with all the form data
      navigate("/account-overview");
      console.log("User Signed up");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <>
      <div className="">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
          <div className="col-xl-12">
              <div className="mini-logo text-center my-5">
                <Link to={"/"}>
                  <img
                    src={require("../../images/reoncapital_logo_transparent_small_small_v2.png")}
                    alt="reon capital"
                  />
                </Link>
              </div>

              <div className="card-header justify-content-center">
                  <h3 className="">Register with our fund</h3>
                </div>

              <div className="auth-form card">
                <div className="card-header justify-content-center">
                  <h4 className="card-title">A little about you</h4>
                </div>

                <div className="card-body">
                  {/* {error && <Alert variant="danger">{error}</Alert>} */}

                  {error && (
                    <Alert variant="danger">
                      {error.split('\n').map((errorMsg, index) => (
                        <div key={index}>{errorMsg}</div>
                      ))}
                    </Alert>
                  )}


                  <Form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="formBasicTitle">
                          <Form.Label>Title</Form.Label>
                          <Form.Control as="select" value={title} onChange={handleTitleChange}>
                           <option value="">Select Title</option>
                            <option value="Mr">Mr</option>
                            <option value="Mrs">Mrs</option>
                            <option value="Miss">Miss</option>
                            <option value="Dr">Dr</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                      <Form.Label>First Name</Form.Label>
                        <Form.Group className="mb-3" controlId="formBasicFirstName">
                          <Form.Control
                            type="text"
                            placeholder="First Name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                      <Form.Label>Middle Name</Form.Label>
                        <Form.Group className="mb-3" controlId="formBasicMiddleName">
                          <Form.Control
                            type="text"
                            placeholder="Middle Name"
                            value={middleName}
                            onChange={(e) => setMiddleName(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                      <Form.Label>Last Name</Form.Label>
                        <Form.Group className="mb-3" controlId="formBasicLastName">
                          <Form.Control
                            type="text"
                            placeholder="Last Name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                    </div>

<div className="row">
  <div className="col-md-4">
    <Form.Group className="mb-3" controlId="formBasicGender">
      <Form.Label>Gender</Form.Label>
      <Form.Control as="select" value={gender} onChange={handleGenderChange}>
        <option value="">Select Gender</option>
        <option value="Male">Male</option>
        <option value="Female">Female</option>
      </Form.Control>
    </Form.Group>
  </div>

  <div className="col-md-2">
    <Form.Label>Date Of Birth</Form.Label>
    <Form.Group className="mb-3" controlId="formBasicDobDay">
      <Form.Control as="select" value={dobDay} onChange={handleDobDayChange}>
        <option value="">Day</option>
        {Array.from({ length: 31 }, (_, index) => (
          <option key={index} value={index + 1}>
            {index + 1}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  </div>
  
  <div className="col-md-3">
  <Form.Label>-</Form.Label>
    <Form.Group className="mb-2" controlId="formBasicDobMonth">
      <Form.Control as="select" value={dobMonth} onChange={handleDobMonthChange}>
        <option value="">Month</option>
        {[
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ].map((month, index) => (
          <option key={index} value={index + 1}>
            {month}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  </div>
  
  <div className="col-md-3">
  <Form.Label>-</Form.Label>
    <Form.Group className="mb-3" controlId="formBasicDobYear">
      <Form.Control as="select" value={dobYear} onChange={handleDobYearChange}>
        <option value="">Year</option>
        {Array.from({ length: 100 }, (_, index) => (
          <option key={index} value={2023 - index}>
            {2023 - index}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  </div>
</div>





                    <div className="row">
                    <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="formPhoneNumber">
                  <Form.Label>Phone Number</Form.Label>
                  <PhoneInput className="form-control"
                    
                      international
                      defaultCountry="GB"
                      // placeholder="Enter phone number"
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                   //   error={phoneNumber ? (isValidPhoneNumber(phoneNumber) ? undefined : 'Invalid phone number') : 'Phone number required'}
                      />
                      </Form.Group>
      </div>



                      {/* <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="formPhoneNumber">
                          <Form.Label>Phone Number</Form.Label>
                          <Form.Control
                            type="tel"
                            inputMode="numeric"
                            pattern="[0-9+]*"
                            placeholder="Phone Number"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Backspace") {
                                return;
                              }
                              const allowedCharacters = /^[0-9+]*$/;
                              if (!allowedCharacters.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </Form.Group>
                      </div> */}

                      <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="formBasicNationality">
                          <Form.Label>Nationality</Form.Label>
                          <div className="custom-flag-select">
                            <ReactFlagsSelect
                              selected={nationality}
                              onSelect={handleNationalitySelect}
                              placeholder="Select Country"
                              searchable={true}
                            />
                          </div>
                        </Form.Group>
                      </div>



                    </div>

                    <div className="row">

                    </div>



                    <div className="row">

                    </div>

{/* #region Address */}
                    <div className="card-header justify-content-center">
                      <h4 className="card-title">Tell us where you live</h4>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="formBasicAddress1">
                          <Form.Label>Street Address</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Address Line 1"
                            value={address1}
                            onChange={(e) => setAddress1(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="formBasicAddress2">
                          <Form.Label>Street Address</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Address Line 2"
                            value={address2}
                            onChange={(e) => setAddress2(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="formBasicCity">
                          <Form.Label>City</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="City"
                            value={addressCity}
                            onChange={(e) => setAddressCity(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="formBasicPostCode">
                          <Form.Label>Post Code</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Post Code"
                            value={addressPostCode}
                            onChange={(e) => setAddressPostCode(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="formBasicCountry">
                          <Form.Label>Country</Form.Label>
                          <div className="custom-flag-select">
                            <ReactFlagsSelect
                              selected={addressCountry}
                              onSelect={handleAddressCountrySelect}
                              placeholder="Select Country"
                              searchable={true}
                            />
                          </div>
                        </Form.Group>
                      </div>
                    </div>


{/* #endregion Address           */}



{/* #region FundRegistration */}

                <div className="card-header justify-content-center">
                    <h4 className="card-title">Fund Registration</h4>
                  </div>



                  <div className="row">
                      <div className="col-md-6">
                      <Form.Group className="mb-3" controlId="formBasicFundName">
                          <Form.Label>Fund</Form.Label>
                          {/* <Form.Control as="select" value={fundName} onChange={handleFundNameChange}>
                            <option value="">Select Title</option>
                            <option value="Amana Fund">Amana Fund</option>
                            <option value="Sare Fund">Sare Fund</option>
                            <option value="Ultra Fund">Ultra Fund</option>                            
                          </Form.Control> */}

                          <Form.Control
                            type="text"
                            readOnly={true}                          
                            defaultValue="Amana Fund"                      
                          />

                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                      <Form.Group className="mb-3" controlId="formBasicfundMonthlyAmount">
                  <Form.Label>Monthly Amount($)</Form.Label>
                        <div style={{ padding: '16px' }}>
                        <Slider
                        value={fundMonthlyAmount}
                        step={25}
                        min={100}
                        max={1000}
                        aria-label="Default"
                        valueLabelDisplay="on"
                        onChange={(e) => handlefundMonthlyAmountChange(e.target.value)}
                      />
                                      </div>
                      </Form.Group>
                      </div>
                    </div>


                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="formBasicLockInPeriod">
                          <Form.Label>Lock In Period</Form.Label>
                          <Form.Control
                            type="text"
                            readOnly={true}                          
                            defaultValue="24 Months"                      
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="formBasicExpectedTotalInvestment">
                          <Form.Label>Total Investment</Form.Label>
                          <Form.Control
                          type="text"
                          readOnly={true}                    
                          value={formattedfundTotalAmountInvested} // Display the formatted calculated value
                         // defaultValue={formattedfundTotalAmountInvested}
                        />
                        </Form.Group>
                      </div>
                    </div>







{/* #endregion FundRegistration */}



<div className="card-header justify-content-center">
                   
                  </div>

                    <div className="row">
                        <div className="col-md-6">
                        <Form.Label>Email</Form.Label>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control
                              type="email"
                              placeholder="Email address"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </Form.Group>
                        </div>

                        <div className="col-md-6">
                      <Form.Label>Password</Form.Label>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Control
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </Form.Group>
                      </div>


                      </div>
              

                    {/* <div className="row">
                      <div className="col-md-6">
                      <Form.Label>Password</Form.Label>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Control
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </Form.Group>
                      </div>

                      <div className="col-md-6">
                      <Form.Label>Confirm Password</Form.Label>
                        <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                          <Form.Control
                            type="password"
                            placeholder="Confirm Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </Form.Group>
                      </div>

                    </div> */}

                    <div className="row">
                     <div className="col-md-12">
                        <div className="row d-flex justify-content-between mt-4 mb-2">
                        <div className="mb-3 mb-0">
                            <label className="toggle">
                            <input
                                className="toggle-checkbox"
                                type="checkbox"
                            />
                            <span className="toggle-switch"></span>
                            <span className="toggle-label">I Agree with the Terms & Conditions</span>
                            </label>
                        </div>
                        <div className="mb-3 mb-0">
                            <Link to={"/reset"}>Forgot Password?</Link>
                        </div>
                        </div>
                    </div>
                    </div>


                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-center">
                          <Button variant="primary" type="submit">
                            Sign Up
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>

                  <div className="new-account mt-3">
                    <p>
                      Already have an account?{" "}
                      <Link className="text-primary" to={"/signin"}>
                        Log In
                      </Link>
                    </p>
                  </div>

                  <div className="new-account mt-3">
                    <p>
                    <Link className="text-primary" to={"/terms-and-conditions"} target="_blank">
          Terms & Conditions
        </Link>

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
