import React from 'react';
import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  updateProfile,
  signOut,
} from "firebase/auth";
import { collection, doc,  getDoc,addDoc, setDoc, updateDoc, query, where, orderBy,getDocs, serverTimestamp, Timestamp } from "firebase/firestore";
import { db,auth } from "./../firebase"; // Import auth from Firebase

import { useNavigate } from 'react-router-dom'; // Assuming you are using react-router-dom for routing
import { toast } from 'react-toastify';

// ...



const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});
  const [transactions, setTransactions] = useState([]);
 // const navigate = useNavigate(); // Initialize useNavigate hook






  // Function to handle user logout
  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    //  navigate('/login'); // Navigate to login page after logout
    toast.error('You have been logged out due to inactivity.');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };





  // Effect to monitor user activity and log out after 30 seconds of inactivity
  useEffect(() => {
    let logoutTimer;
    let toastTimer;

    const resetTimers = () => {
      clearTimeout(logoutTimer);
      clearTimeout(toastTimer);
      logoutTimer = setTimeout(handleLogout, 10 * 60 * 1000); // 10 minutes logout in milliseconds
    };

    const handleActivity = () => {
      resetTimers();
      clearTimeout(toastTimer);
      toastTimer = setTimeout(() => {
        toast.info('You have been inactive for 10 seconds.');
      }, 5 * 60 * 1000); // 5 minutes timeout  in milliseconds
    };

    // Reset timer on user activity (mousemove and keypress events)
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);

    // Initialize timers
    resetTimers();

    // Cleanup
    return () => {
      clearTimeout(logoutTimer);
      clearTimeout(toastTimer);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
    };
  }, []); // Dependency array is empty to run only once on component mount

  // Effect to handle user authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);







  // STAFRT irebase Authenication \\
//#region  Firebase Authenication
function UserSignIn(email, password) {
  return signInWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      const user = userCredential.user;

      // Update the 'loginDateTime' field and increment 'noOfLogins' in the Firestore 'clients' document
      const clientsCollection = collection(db, "clients");
      const userDocRef = doc(clientsCollection, user.uid);

      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();

        // Increment the 'noOfLogins' field
        const updatedNoOfLogins = (userData.noOfLogins || 0) + 1;

        // Update the document with new values
        await updateDoc(userDocRef, {
          noOfLogins: updatedNoOfLogins,
          loginDateTime: serverTimestamp(), // Set loginDateTime to the current timestamp
        });

        console.log("User logged in: ", user.uid);

        return user;
      } else {
        throw new Error("Client not found.");
      }
    })
    .catch((error) => {
      // Handle login errors here
      if (error.code === "auth/invalid-email") {
        throw new Error("Invalid email address.");
      }else if (error.code === "auth/missing-password") {
        throw new Error("Missing password");
      }  
      else if (error.code === "auth/invalid-credential") {
        throw new Error("Invalid login credentials. Please check your email and password.");
      }  
      else if (error.code === "auth/invalid-login-credentials") {
        throw new Error("Invalid login credentials. Please check your email and password.");
      } else {
        throw error;
      }
    });
}


async function UserSignUp(email, password, fullName, title, firstName, middleName, lastName
  , gender
  , dob
  ,phoneNumber  , nationality
  ,address1, address2, addressCity, addressPostCode, addressCountry
  ,fundName ,fundMonthlyAmount, fundTotalAmountInvested
    ) {
  try {
        // Check if any required parameter is empty
        if (
          !email ||
          !password ||
          !fullName ||
          !title ||
          !firstName ||
          !middleName ||
          !lastName ||
         !gender ||
          !dob ||
          !phoneNumber ||
          !nationality ||
          !address1 ||
          // !address2 ||   
          !addressCity ||
          !addressPostCode ||
          !addressCountry ||
          !fundName ||
          !fundMonthlyAmount ||
          !fundTotalAmountInvested
        ) {
          throw  Error("All parameters are required.");
        }

    // Create a new user using Firebase Authentication
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

  // Update the user's display name
    await updateProfile(userCredential.user, { displayName: fullName });



    // Create a new document reference with the clientUID as the document ID
    const clientsCollection = collection(db, "clients");
    const newClientDocRef = doc(clientsCollection, user.uid);

 



  // Fund Array
      // Calculate the first day of the next month
  const nextMonth = new Date();
  nextMonth.setMonth(nextMonth.getMonth() + 1);
  nextMonth.setDate(1);
  // Create a Firestore Timestamp from the calculated date
  const fundStartDate =   Timestamp.fromDate(nextMonth);

  const fundEndDate = new Date(nextMonth);
 fundEndDate.setMonth(fundEndDate.getMonth() + 24);

 const fundMaturityEndDate = new Date(nextMonth);
fundMaturityEndDate.setMonth(fundMaturityEndDate.getMonth() + 12);




    const fundX = {
      fundName: "Amana Fund", //fundName,
      fundType: "Fully Managed Fund",
      fundFunding: "Monthly",
      fundCurrency: "USD",
      fundMonthlyAmount: fundMonthlyAmount,
      fundTotalInvestment: fundTotalAmountInvested, // Assuming monthly funding for 24 months
      fundStartDate: fundStartDate,
      fundEndDate:  Timestamp.fromDate(fundEndDate), // fundEndDate.toISOString(),
      fundLockPeriodMonths: 24,
      fundMaturityMonths: 36,
      fundMaturityEndDate:Timestamp.fromDate(fundMaturityEndDate), //.toISOString(),
      fundPaymentMonthNo: 0,
      fundPaymentMonthsLeft: 24, // Assuming 24 months left for payments
    };



    const notifications = {
      transactional: true,  
      fundAnnouncements: true,
      globalMarketsAnnouncements: true,
      accountRecommendations: true,

    };

    // Set the data for the document
    await setDoc(newClientDocRef, {
      clientUID: user.uid,
      email: email,
      password: password,
      title: title,      
      fullName: fullName,
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      gender: gender,
      dob: dob,
    phoneNumber: phoneNumber,
    nationality: nationality,
    address1: address1,
    address2: address2,
    addressCity: addressCity,
    addressPostCode: addressPostCode,
    addressCountry: addressCountry,
    notifications: notifications,

   fundX: fundX,
   accountManagerId: "1",
   accountBalance: 0,
   accountBalanceAvailable:0,
   timeZone: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',



    accountStatusId: 1,
    isAccountAproved: false,
    noOfLogins: 1,
    accountCreateDate: serverTimestamp(),
    loginDateTime: serverTimestamp(),
    // Add other user details as needed
    });

    //send Email verification
      // Send Email verification
      await sendEmailVerification(user);

    return user;
  } catch (error) {
    // Handle any signup errors here
    if (error.code === "auth/invalid-email") {
      throw new Error("Invalid email address.");
    } else if (error.code === "auth/weak-password") {
      throw new Error("Weak password. Please use a stronger password.");
    } else if (error.code === "auth/email-already-in-use") {
      throw new Error("Email already in use. Sign In or Reset password");
    }
    else if (error.code === "auth/missing-email") {
      throw new Error("Enter your email address");
    }
    else if (error.code === "All parameters are required.") {
      throw new Error("All parameters are required.");
    }
     else {
 // Log the error to Firestore collection logsWeb
 const logsWebCollection = collection(db, "logsWeb");
 const logData = {
   timestamp: serverTimestamp(),
   errorMessage: error.message,
   errorStack: error.stack || "",
 };
 await addDoc(logsWebCollection, logData);

     throw error;
    // throw  Error("You have run into an issue, we'll look into this issue for you.");
    }
  }
}



  function LogOut() {
    console.log("User logged out:", user.uid);
    return signOut(auth);
  }



//#endregion  Firebase Authenication
  // END Firebase Authenication \\



  //#region Database Firestore 

// Function to get client data by UID
async function getClient(uid) {
  try {
    const clientsCollection = collection(db, "clients");
    const userDocRef = doc(clientsCollection, uid);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      console.log("userData :", userData);
      return userData;
    } else {
      throw new Error("Client not found.");
    }
  } catch (error) {
    // Handle errors here
    throw error;
  }
}



  // Get transactions effect
  useEffect(() => {
    async function fetchTransactions() {
      try {
        if (user && user.uid) {
          console.log("User UID:", user.uid);
  
          const transactionsCollection = collection(db, "transactions");
          const transactionsQuery = query(
            transactionsCollection,
            where("clientUID", "==", user.uid),
            orderBy("transactionDateTime", "desc")
          );
          const transactionsSnapshot = await getDocs(transactionsQuery);
  
          // Log the number of documents in the snapshot
          console.log("Number of documents:", transactionsSnapshot.size);
  
          const transactionsData = transactionsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          
          // Log the data fetched from Firestore
          console.log("transactionsData:", transactionsData);
  
          setTransactions(transactionsData);
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    }
  
    // Call the fetchTransactions function
    fetchTransactions();
  }, [user]);
  



  async function updateClient(uid, updatedFields) {
    try {
      const clientsCollection = 'clients'; // Replace with your Firestore collection name
  
      // Create a reference to the client document
      const userDocRef = doc(db, clientsCollection, uid);
  
      // Update the document with the provided fields
      await updateDoc(userDocRef, updatedFields);
  
      console.log('Client updated successfully.');
    } catch (error) {
      console.error('Error updating client:', error);
      throw error;
    }
  }




  //#endregion Database Firestore


  // Authentication state change effect
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);



  return (
    <userAuthContext.Provider
      value={{ user, UserSignIn, UserSignUp, LogOut, getClient, transactions, handleLogout  }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}


