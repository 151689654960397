import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './pages/homePage';
import Dashboard from './pages/dashboard';
import Exchange from './pages/exchange';
import Tbi from './pages/data-tbi';
import Settings from './pages/settings';
// import AccountAffiliate from './pages/account-affiliate';
// import AccountApi from './pages/account-api';
import AccountDeposit from './pages/account-deposit';
import AccountOverview from './pages/account-overview';
import AccountWithdraw from './pages/account-withdraw';
import BankAcc from './pages/add-bank-acc';
import DebitCard from './pages/add-debit-card';
import FundingRate from './pages/data-funding-rate';
import IndexPrice from './pages/data-index-price';
import InsuranceFund from './pages/data-insurance-fund';
import LastPrice from './pages/data-last-price';
import MarkPrice from './pages/data-mark-price';
// import Demo from './pages/demo';
import Lock from './pages/lock';
import Otp1 from './pages/otp-1';
import Otp2 from './pages/otp-2';
import Reset from './pages/reset';
import SettingAccount from './pages/settings-account';
import Preferences from './pages/settings-preferences';
import Verification from './pages/verification';
import Signin from './pages/signin';
import Signup from './pages/signup';
import VerifyStep1 from './pages/verify-step-1';
import VerifyStep2 from './pages/verify-step-2';
import VerifyStep3 from './pages/verify-step-3';
import VerifyStep4 from './pages/verify-step-4';
import VerifyStep5 from './pages/verify-step-5';
import VerifyStep6 from './pages/verify-step-6';

import AboutUs from './pages/aboutus';
import TermsAndConditions from './pages/terms-and-conditions';
// import Error404 from './pages/404';





import { UserAuthContextProvider, useUserAuth } from "../context/UserAuthContext";


function Index() {
  const { user } = useUserAuth();

  
  return (
    <>
      <UserAuthContextProvider> {/* Wrap your entire component with UserAuthContextProvider */}
        <Router>
            <div id="main-wrapper">
            <Routes>
                <Route path="/" element={<Homepage />} />


           


                {/* <Route path="/exchange" element={user ? <Exchange /> : <Signin />} /> */}
                <Route path="/account-overview" element={user ? <AccountOverview /> : <Signin />} />
                <Route path="/data-tbi" element={user ? <tbi /> : <Signin />} />
                <Route path="/settings" element={user ? <Settings /> : <Signin />} />
                {/* <Route path="/account-affiliate" element={<AccountAffiliate />} /> */}
                {/* <Route path="/account-api" element={<AccountApi />} /> */}
                <Route path="/account-deposit" element={user ? <AccountDeposit /> : <Signin />} />
                <Route path="/account-withdraw" element={user ? <AccountWithdraw /> : <Signin />} />
                <Route path="/add-bank-acc" element={user ? <BankAcc /> : <Signin />} />
                <Route path="/add-debit-card" element={user ? <DebitCard /> : <Signin />} />
                <Route path="/data-funding-rate" element={user ? <FundingRate /> : <Signin />} />
                <Route path="/data-index-price" element={user ? <IndexPrice /> : <Signin />} />
                <Route path="/data-insurance-fund" element={<InsuranceFund />} />
                <Route path="/data-last-price" element={user ? <LastPrice /> : <Signin />} />
                <Route path="/data-mark-price" element={user ? <MarkPrice /> : <Signin />} />
                {/* <Route path="/demo" element={<Demo />} /> */}
                {/* <Route path="/lock" element={user ? <Dashboard /> : <Lock />} /> */}
                <Route path="/otp-1" element={user ? <Otp1 /> : <Signin />} />
                <Route path="/otp-2" element={user ? <Otp2 /> : <Signin />} />
                <Route path="/reset" element={user ? <Reset /> : <Signin />} />
                <Route path="/settings-account" element={user ? <SettingAccount /> : <Signin />} />
                <Route path="/settings-preferences" element={user ? <Preferences /> : <Signin />} />
                <Route path="/verification" element={user ? <Verification /> : <Signin />} />
                <Route path="/signin" element={<Signin />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/verify-step-1" element={user ? <VerifyStep1 /> : <Signin />} />
                <Route path="/verify-step-2" element={user ? <VerifyStep2 /> : <Signin />} />
                <Route path="/verify-step-3" element={user ? <VerifyStep3 /> : <Signin />} />
                <Route path="/verify-step-4" element={user ? <VerifyStep4 /> : <Signin />} />
                <Route path="/verify-step-5" element={user ? <VerifyStep5 /> : <Signin />} />
                <Route path="/verify-step-6" element={user ? <VerifyStep6/> : <Signin />} />

                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

                    {/* Add your other routes here */}
                    {/* <Route component={Error404} />  */}
                    {/* This is your 404 route */}
                


            </Routes>
            </div>
        </Router>
      </UserAuthContextProvider>
    </>
  );
}



export default Index;
