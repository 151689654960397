import React, { } from 'react';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

class TimeDatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: this.props.dateSelected || new Date()
        };
    }

    handleChange = date => {
        this.setState({
            startDate: date
        });
    };

    render() {
        return (
            <DatePicker className="form-control"
                selected={this.state.startDate}
                onChange={this.handleChange}
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                dateFormat="dd/MMM/YYYY"
            />
        );
    }
}

export default TimeDatePicker;